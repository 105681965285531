import React from 'react'

export default function VideoDash() {
  return (
    <>
        <video width="800" height="1080" className='z-auto' controls >
            <source src="https://grupomassa.com.br/wp-content/uploads/2024/11/MANIFESTO_GRUPOMASSA_TVIGUACU_1920X1080%20%281%29.mp4" type="video/mp4"/>
        </video>        
    </>
  )
}
