import React, {useState, useEffect} from "react"

export default function GetRssSolucoes(){
    const mediumRssFeed = "https://solucoesmassa.com.br/wp-json/wp/v2/posts?_embed"

    const MAX_ARTICLES = 4;
    const [articles, setArticles] = useState();

    useEffect(() => {
        const loadArticles = async () => {
            fetch(mediumRssFeed, { headers: { Accept:"application/json" } })
            .then((res) => res.json())
            .then((data) =>
                data.map((post) => ({
                    title: post.title.rendered,
                    url: post.link,
                    content_text: post.excerpt.rendered.replace(/<[^>]*>?/gm, ""), // Remove HTML do excerpt
                    image: post.featured_media ? post._embedded["wp:featuredmedia"][0].source_url : null
                }))
            )
            .then((newArticles) => Array.from(newArticles).slice(0, MAX_ARTICLES))
            .then((articles) => setArticles(articles))
            .catch((error) => console.log(error));
        };

        loadArticles();

    }, [MAX_ARTICLES]);

    return (
        <>
        {articles
            ? 
            <>
            <div class="grid gap-3 pt-2 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
                {articles.map((item, index) => (
                    <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow flex flex-1 flex-col justify-between">
                        <div>
                            <a href="/#">
                                <img class="rounded-t-lg" src={item.image} alt="" />
                            </a>
                            <div class="px-5 pt-5 pb-1">
                                <a href="/#" title={item.title}>
                                    <h5 class="mb-2 text-md font-500 tracking-tight text-gray-900 line-clamp-2">{item.title}</h5>
                                </a>
                                <p class="mb-3 text-xs font-300 text-gray-600 line-clamp-5">{item.content_text.substring(0, 120)}...</p>
                            </div>
                        </div>
                        <div class="pb-5 pt-0 px-5">
                            <a href={item.url} class="w-full inline-flex justify-center items-center mt-2 px-[37px] py-[10px] bg-[#2785DD] rounded-md text-white font-light text-sm" target="_blank" rel="noopener noreferrer">
                                Continue lendo
                            </a>
                        </div>
                    </div>
            )) }
            </div>
            </>
            : "Nenhum post no momento."}
        </>
    )
}