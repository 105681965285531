import React, {useState, useEffect, useRef} from 'react'
import { BsChevronDown, BsBuildings, BsFillPeopleFill, BsGraphUp } from 'react-icons/bs'

import { RiDashboardFill } from 'react-icons/ri'
import { FaDollarSign, FaUser, FaUsersCog, FaStoreAlt, FaHistory } from 'react-icons/fa'
import { IoReader } from "react-icons/io5";
import { IoMdSettings, IoMdHome } from 'react-icons/io'
import { GiHamburgerMenu } from 'react-icons/gi'
import { BiExit } from 'react-icons/bi'
import { HiPresentationChartLine } from 'react-icons/hi'
import { Link } from "react-router-dom"
import Avatar  from './Avatar'

export default function Sidebar() {
  const [abrir, setAbrir] =  useState(false);
  const [abrirSubmenu, setAbrirSubmenu] =  useState(false);
  const [showItem, setShowItem] = useState(true);
  const logged = (localStorage.getItem('token')) ? true : false;
  const timeoutRef = useRef(null);

  const Menu = [
    { titulo: "Home", icone: <IoMdHome />, link: "/dash", showItemMenu: true},
    { titulo: "Financeiro", icone: <FaDollarSign />, link: "/financeiro", showItemMenu: true},
    { titulo: "Histórico de Compras", icone: <FaHistory />, link: "/historico-de-compras", showItemMenu: true},
    { titulo: "Comprovantes de Veiculação", icone: <IoReader />, link: "/comprovantes-de-veiculacao", showItemMenu: true}, //Ocultado do Menu em 16/11/2023 - Camila S.
    { titulo: "Meus Dados", espacamento: true, icone: <FaUser />, link: "/meus-dados", showItemMenu: true},
    { titulo: "Configurações", icone: <IoMdSettings />, link: "",  group: 'admin',
    submenu: true, showItemMenu: ((localStorage.getItem('group') === 'admin' || localStorage.getItem('group') === 'admin_cliente') ? true : false),
        submenuItems: [ 
          {titulo: "Agências", showSubItemMenu: ((localStorage.getItem('group') === 'admin' || localStorage.getItem('group') === 'admin_cliente') ? true : false), icone: <BsBuildings />, link: "/configuracoes/gestao-de-agencias"},
          {titulo: "Clientes", showSubItemMenu: ((localStorage.getItem('group') === 'admin' || localStorage.getItem('group') === 'admin_cliente') ? true : false), icone: <BsFillPeopleFill />, link: "/configuracoes/gestao-de-clientes"},
          {titulo: "Empresas", showSubItemMenu: ((localStorage.getItem('group') === 'admin' || localStorage.getItem('group') === 'admin_cliente') ? true : false), icone: <FaStoreAlt />, link: "/configuracoes/gestao-de-empresas"},
          {titulo: "Estatísticas", showSubItemMenu: ((localStorage.getItem('group') === 'admin_cliente') ? false : true), icone: <BsGraphUp />, link: "/configuracoes/estatisticas"},
          {titulo: "Usuários", showSubItemMenu: ((localStorage.getItem('group') === 'admin' || localStorage.getItem('group') === 'admin_cliente') ? true : false), icone: <FaUsersCog />,  link: "/configuracoes/gestao-de-usuarios"},
        ] 
    },
    { titulo: "Sair", icone: <BiExit />, link: "/logout", showItemMenu: true}
  ];

  const handleMouseEnter = () => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setAbrir(true);
    }, 200);
  };

  const handleMouseLeave = () => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setAbrir(false);
    }, 200);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <>
      {(logged) ? <div>
        <div className='hidden lg:flex'>
            <div className={`fixed shadow-lg shadow-slate-400 top-0 col-span-3 min-h-screen p-5 z-50 pt-8 duration-300 bg-sidebar ${abrir ? "w-72" : "w-20 h-full"}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              {/* <FiChevronsRight size={30} onClick={()=> setAbrir(!abrir)} className={`bg-white text-slate-800 shadow-sm shadow-slate-300 rounded-md absolute -right-3 top-4 p-1 cursor-pointer text-blue-gm ${abrir && "rotate-180 text-blue-600"}`} /> */}
              <div className='inline-flex w-full'>
                <Avatar abrir={abrir} color='getTipoUsuariowhite'/>
              </div>
              <div className={`inline-flex w-full ${!abrir && "hidden"}`}>
                  <p className="mx-auto text-menu-avatar">Olá, {localStorage.getItem('user').split(' ')[0]}</p>
              </div>
              <div class="w-full">
                  <ul className={`pt-2 mx-auto lista-menu overflow-auto grid ${abrir ? "pl-2 mt-10" : "justify-center mt-[7.3rem]"}`}>
                    {Menu.map((menu, index) => (
                      <>
                        {menu.showItemMenu && (
                          
                            <li key={index} className={`flex items-center py-2 gap-x-4 cursor-pointer rounded-md ${menu.espacamento ? "mt-9" : "mt-2"}`}>
                                <span className='text-2xl block float-left'>
                                {/* {menu.icone ? (
                                  <Link to={menu.link}>{menu.icone}</Link>
                                ) : (
                                  <Link to={menu.link}>
                                    <RiDashboardFill />
                                  </Link>
                                )} */}
                                {menu.icone && !menu.submenu ? (
                                  <Link to={menu.link}>{menu.icone}</Link>
                                  ) : (
                                  <span className='cursor-default'>{menu.icone || <RiDashboardFill />}</span>
                                )}
                                </span>
                                <span className={`text-sm font-normal flex-1 duration-200 pt-1 ${!abrir && "hidden"}`}>
                                {menu.submenu
                                  ?
                                  (
                                    <button
                                      onClick={() => {
                                        setAbrirSubmenu(!abrirSubmenu);
                                        setAbrir(true); // Garante que a sidebar fique aberta
                                      }}
                                      className="flex items-center w-full text-left"
                                    >
                                      {menu.titulo}
                                      <BsChevronDown
                                        className={`${abrirSubmenu && "rotate-180"} duration-200 my-auto ml-3 text-xl`}
                                      />
                                    </button>
                                  )
                                  :
                                  (
                                    <Link to={menu.link}>{menu.titulo}</Link>
                                )}
                                </span>
                              
                            </li>)}
                            {menu.submenu && abrirSubmenu && abrir && menu.showItemMenu &&(
                              <ul>
                                {menu.submenuItems.map((submenuItem, index) => (
                                  <>
                                  {submenuItem.showSubItemMenu && (
                                    <Link to={submenuItem.link}>
                                      <li key={index} className='text-sm flex items-center gap-x-4 cursor-pointer p-2 rounded-md ml-5'>
                                        <span className='block float-left'>
                                            {submenuItem.icone ? submenuItem.icone : ''}
                                        </span>
                                        {submenuItem.titulo}
                                      </li>
                                    </Link>
                                  )}
                                  </>
                                ))}
                              </ul>
                            )}
                      </>
                    ))}
                  </ul>
              </div>
              <div className="w-full sticky py-1 top-[100vh] bg-sidebar">
                <img class="mx-auto bg-sidebar" height="40px" width="106px" src="/img/logo-solucoes-massa-white.png" alt="banner"/>
              </div>
            </div>
        </div>
        <div className='flex lg:hidden'>
        <nav className="bg-sidebar shadow-sm fixed shadow-slate-600 border-gray-200 px-2 sm:px-4 py-3 w-full h-16 z-40">
            <div className="block flex-wrap mx-auto">
                <div className="flex">
                  <img className="mx-auto bg-sidebar" height="40px" width="106px" src="https://portal.solucoesmassa.com.br/img/logo-solucoes-massa-white.png" alt="banner"/>
                  <div className='fixed right-12'>
                    <GiHamburgerMenu size={40} onClick={()=> setAbrir(!abrir)} className={`absolute p-1 cursor-pointer text-white ${abrir && "rotate-180 text-blue-600"}`} />
                  </div>
                </div>
            </div>
        </nav>
        <div className={`fixed top-0 col-span-3 min-h-screen pt-8 z-50 duration-300 bg-sidebar ${abrir ? "w-72" : "w-0 h-full p-0"}`}>
          <div className='inline-flex w-full'>
                <Avatar color='getTipoUsuariowhite' abrir={abrir}/>
              </div>
              <div className={`inline-flex w-full ${!abrir && "hidden"}`}>
                  <p className="mx-auto text-menu-avatar">Olá, {localStorage.getItem('user').split(' ')[0]}</p>
              </div>
              <div class="w-full overflow-hidden">
                  <ul className='pt-2 mx-auto lista-menu'>
                    {Menu.map((menu, index) => (
                      <>
                        {menu.showItemMenu && (
                          
                            <li key={index} className={`flex items-center py-1 gap-x-4 cursor-pointer rounded-md ${menu.espacamento ? "mt-9" : "mt-2"}`}>
                                <span className='text-2xl block float-left'>
                                  {menu.icone ? menu.icone : <RiDashboardFill />}
                                </span>
                                <span className={`text-base font-normal flex-1 duration-200 ${!abrir && "hidden"}`}>
                                {menu.submenu
                                  ?
                                  (
                                    <button
                                      onClick={() => {
                                        setAbrirSubmenu(!abrirSubmenu);
                                        setAbrir(true); // Garante que a sidebar fique aberta
                                      }}
                                      className="flex items-center w-full text-left"
                                    >
                                      {menu.titulo}
                                      <BsChevronDown
                                        className={`${abrirSubmenu && "rotate-180"} duration-200 my-auto ml-3 text-xl`}
                                      />
                                    </button>
                                  )
                                  :
                                  (
                                    <Link to={menu.link}>{menu.titulo}</Link>
                                )}
                                </span>
                              
                            </li>)}
                            {menu.submenu && abrirSubmenu && abrir && menu.showItemMenu &&(
                              <ul>
                                {menu.submenuItems.map((submenuItem, index) => (
                                  <Link to={submenuItem.link}>
                                    <li key={index} className='text-sm flex items-center gap-x-4 cursor-pointer p-2 rounded-md ml-5'>
                                      <span className='block float-left'>
                                          {submenuItem.icone ? submenuItem.icone : ''}
                                      </span>
                                      {submenuItem.titulo}
                                    </li>
                                  </Link>
                                ))}
                              </ul>
                            )}
                      </>
                    ))}
                  </ul>
              </div>
              <div className="w-full sticky py-1 top-[100vh] bg-sidebar">
                <img class="mx-auto bg-sidebar" height="40px" width="106px" src="/img/logo-solucoes-massa-white.png" alt="banner"/>
              </div>
        </div>
        </div>
        </div>
        :
        <span className='text-center justify-center flex text-slate-600'>Carregando...</span>
      }
    </>
  )
}