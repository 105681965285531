import React, {useState} from 'react'
import InfoTopBorder from '../Alert/InfoTopBorder'
import Modal from '../Modal'
import FormVincularEmpresa from '../Form/FormVincularEmpresa';
import DashCarouselEmpresas from '../DashCarouselEmpresas'
import DashFullSlider from '../DashFullSlider'
import DashBannerBoasvindas from '../DashBannerBoasvindas'
import DashSlider from '../DashSlider'
import GetRssSolucoes from '../RSS/BlogSolucoes'

export default function Dashboard() {
    const [showModal, setOpenModal] = useState(false);
    const openModal = () => {
        setOpenModal(true);
    }
    const closeModal = () => {
        setOpenModal(false);
    }

  return (
    <>
      <div>
        {(localStorage.getItem("empresas_vinculadas") === 'false') ? 
          <>
          <InfoTopBorder 
          titulo="Seu usuário está sem vínculo com uma Empresa." 
          texto="Para acessar todos os recursos e funcionalidades disponíveis, é necessário vincular-se, por favor, clique no botão abaixo e preencha as informações necessárias."
          textoBotao="Vincular Empresa"
          actionBotao={openModal}
          />{showModal && <Modal closeModal={closeModal} conteudo={FormVincularEmpresa}/>}
          </>
          : ''
          }
      </div>
      <div className='grid grid-cols-1 xl:grid-cols-3 mt-3 p-5'>
        <div className='col-span-2'>
          <DashBannerBoasvindas/>
        </div>
        <div className='col-span-1 mt-2 lg:mt-0'>
          <DashSlider/>
        </div>
      </div>
      <div className='p-5'>
        <DashCarouselEmpresas />
      </div>
      <div className='p-5'>
        <DashFullSlider />
      </div>
      <div className='p-5'>
          <GetRssSolucoes />
      </div>
    </> 
  )
}