import React, { useState, useEffect } from 'react';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import {BsPlayCircle} from 'react-icons/bs';
import { RxDotFilled } from 'react-icons/rx';
import Modal from './Modal';
import VideoDash from './Modal/VideoDash';

function DashSlider() {
  const [showModal, setOpenModal] = useState(false);
  const openModal = () => {
      setOpenModal(true);
  }
  const closeModal = () => {
      setOpenModal(false);
  }
  const slides = [
    {
      url: 'https://grupomassa.com.br/wp-content/uploads/2024/11/BANNER_SIMPLES_COMO_DEVE_SER_440px440.webp',
      show_video:true
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const autoplay = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };
  
  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  useEffect(() => {
    const interval = setInterval(autoplay, 6000);
    return () => clearInterval(interval);
  }, [currentIndex]);

  return (
    // <div className='max-w-[433px] h-[368px] w-full m-auto relative group'>
    <>
    <div className='max-w-[480px] h-[400px] w-full m-auto relative group'>
      <div
        style={{ backgroundImage: `url(${slides[currentIndex].url})` }}
        className='w-full h-full rounded-lg bg-center bg-cover duration-500'
      >
        {(slides[currentIndex].show_video) ? <div className='flex flex-col items-center h-full'><button className="m-auto text-blue-gm text-8xl" onClick={openModal}><BsPlayCircle /></button> </div> : ''}
      </div>
      <AiOutlineLeft
            onClick={prevSlide}
            className="absolute left-0 text-2xl inset-y-1/2 text-white cursor-pointer"
          />
      <AiOutlineRight
            onClick={nextSlide}
            className="absolute right-0 text-2xl inset-y-1/2 text-white cursor-pointer"
          />
      <div className='flex top-4 justify-center -mt-10'>
        {slides.map((slide, slideIndex) => (
          <div
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
            className={`cursor-pointer ${
                slideIndex === currentIndex ? 'text-blue-500 text-4xl -mt-[6px]' : 'text-2xl text-white opacity-85 hover:opacity-100 hover:text-blue-400'
              }`}
          >
            <RxDotFilled />
          </div>
        ))}
      </div>
    </div>
    {(showModal && <Modal closeModal={closeModal} conteudo={VideoDash} classe={false} />)}</>
  );
}

export default DashSlider;