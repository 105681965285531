import React, { useState } from 'react';
import { loginFields } from '../components/constants/formFields'
import Input from "./Input";
import RodapeLogin from './RodapeLogin';
import FormAction from './FormAction';
import { useNavigate } from "react-router-dom";

const fields = loginFields;
let fieldsState = {};
fields.forEach(field => fieldsState[field.id] = '');

// async function loginUser(credentials) {
//     return fetch(process.env.REACT_APP_API_BACK_URL + '/api/login', {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//             'Accept': 'application/json'
//         },
//         body: JSON.stringify(credentials)
//     })
//         .then(data => { 
//             return data.json()    
//         })
//         .catch(error => { alert('Problema de comunicação, tente novamente mais tarde.'); console.log('err'); console.log(error); })
// }
async function loginUser(credentials) {
    return fetch(process.env.REACT_APP_API_BACK_URL + '/api/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
    .then(async (response) => {
        const data = await response.json();
        if (!response.ok) {
            throw new Error(data.error || 'Erro ao fazer login');
        }
        // return data.json();
        return data;
    })
    .catch(error => {
        return { success: false, error: error.message };
    });
}

export default function Login() {
    const [loginState, setLoginState] = useState(fieldsState);
    const [alert, setAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const [ipUser, setIPUser] = useState(() => {
        setLoading(true);
        fetch('https://api.ipify.org/?format=json')
        .then(response => response.json())
        .then(data => {
            if(typeof(data.ip) != 'undefined'){
                setIPUser(data.ip);
            } else{
                setIPUser('');
            }
        })
        .catch((err) => {
            setIPUser('');
        })
        .finally(() => {
            setLoading(false);
        })
     });
    const navigate = useNavigate();

    const handleChange = (e) => {
        setLoginState({ ...loginState, [e.target.id]: e.target.value });
    }

    const handleSubmit = async e => {
        e.preventDefault();
        
        setLoading(true);

        const response = await loginUser({
            email:loginState['email'],
            password:loginState['password'],
            ip: ipUser,
            user_agent: window.navigator.userAgent,
        });

        setLoading(false);

        if(response.success === true){
            setAlert(false);
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('user', response.data.name);
            localStorage.setItem('avatar', response.data.avatar);
            localStorage.setItem('group', response.data.group);
            localStorage.setItem('empresas_vinculadas', response.data.empresas_vinculadas);
            navigate("/dash", { replace: true });
        }else{
            setAlert(true);
            // setAlertMsg(response.data.error);
            setAlertMsg(response.error);
            
            // Se o erro indicar que o login deve ser pelo Google, adicionamos um botão para redirecionamento
            if (response.error && response.error.includes("encontrado mas o modo de login")) {
                setAlertMsg(response.error + " Por favor, utilize o botão de login do Google abaixo.");
            }
        }

    }
    const authenticateUser = () => {
        //INTEGRAÇÃO COM LOGIN AQUI
    }

    return (
        <form className="mt-2 space-y-6" onSubmit={handleSubmit}>
            {/* @TODO: transformar esse alerta em um módulo 
            <div style={{display:(alert? "block": "none")}} className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4" role="alert">{alertMsg}</div>*/}
            
            {/* Alerta de erro */}
            {alert && alertMsg && (
                <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
                    {alertMsg}
                </div>
            )}
            <div className="-space-y-px">
                {
                    fields.map(field =>
                        <Input
                            key={field.id}
                            handleChange={handleChange}
                            value={loginState[field.id]}
                            labelText={field.labelText}
                            labelFor={field.labelFor}
                            id={field.id}
                            name={field.name}
                            type={field.type}
                            isRequired={field.isRequired}
                            placeholder={field.placeholder}
                        />
                    )
                }
            </div>
            <RodapeLogin />
            <FormAction handleSubmit={handleSubmit} text="Entrar" loading={loading} />
        </form>
    )
}