import React, { useState, useEffect } from 'react'
import Tabela, { FiltroCliente, FiltroCnpj, FiltroData, FiltroStatus, FiltroPi, StatusTag, dateBetweenFilterFn, rangeFilterFn } from './Tabela'
import { FiltroDeData } from './FiltroData';
import { FiltroSlider } from './FiltroSlider';
// import { data } from './HistData'
import { FiDownload } from 'react-icons/fi';

export default function HistoricoCompra() {
  const [loading, setLoading] = useState(true);
  const [beginSearch, setBeginSearch] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [totalItens, setTotalItens] = useState('');
  const [compras, setCompras] = useState([]);
  const [numPi, setNumPi] = useState(null);
  const [filtroEmpresas, setFiltroEmpresas] = useState([]);
  const [filtroMinMax, setfiltroMinMax] = useState(() => {
    // console.log('setfiltroMinMax');
    return [100, 200000]
  });

  // console.log('React.version');
  // console.log(React.version);

  // const [compras, setCompras] = useState(() => {
  //   setLoading(true);
  //   setBeginSearch(false);
  //   const token = localStorage.getItem("token");
  //   fetch(process.env.REACT_APP_API_BACK_URL + '/api/list/historico-de-compras/' + pageSize + '?page=1', {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Accept': 'application/json',
  //       'Authorization': "Bearer " + token
  //     }
  //   })
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setTotalItens(data.data.total);
  //       setCompras(data.data.compras.data);
  //     })
  //     .catch((err) => {
  //       // Erro
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // });

  function baixar(pi) {
    alert(pi)
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "Cliente",
        accessor: "cliente",
        width: 180,
        minWidth: 180,
        maxWidth: 180,
        Filter: FiltroCliente
      },
      {
        Header: "CNPJ",
        accessor: "cnpj",
        width: 100,
        Filter: FiltroCnpj
      },
      {
        Header: "Data",
        accessor: "data",
        width: 80,
        Filter: FiltroDeData,
        filter: dateBetweenFilterFn,
        Cell: ({ value }) => value.split('-').reverse().join('/')
      },
      {
        Header: "Valor",
        accessor: "valor",
        Filter: FiltroSlider,
        filter: rangeFilterFn,
        width: 100,
        filtroRange: filtroMinMax,
        //Cell: ({ value }) => value.toLocaleString('pt-br', {minimumFractionDigits: 2})
        //Cell: ({ value }) => value
        Cell: ({ value }) => { return value == null ? "0,00" : value.toLocaleString('pt-br', {minimumFractionDigits: 2})}
      },
      {
        Header: "Status",
        accessor: "status",
        width: 120,
        Cell: StatusTag,
        Filter: FiltroStatus
      },
      {
        Header: "Nº de PI",
        accessor: "nPI",
        width: 120,
        // Filter: FiltroPi,
      },
      {
        Header: "Baixar PI",
        accessor: "url_publica",
        width: 90,
        Cell: ({ row }) => {
          return (
            <>
              {
                row.values.url_publica === null ? (
                  <a href='' style={{ cursor: "not-allowed" }} className='flex w-[68px] text-white rounded bg-[#656565] font-normal text-sm py-2 px-6' title='PI não pode ser baixada'>
                    <FiDownload className='text-white text-xl' />
                  </a>
                ) : (
                  <a className='flex w-[68px] text-white rounded bg-[#1346ED] font-normal text-sm py-2 px-6' href={row.values.url_publica} target='_blank'>
                    <FiDownload className='text-white text-xl' />
                  </a>
                )
              }
            </>
          );
        }
      }
      // ,
      // {
      //   accessor: "url_publica",
      //   show: false,
      //   // Filter: FiltroPi,
      // }
      // ,
      // {
      //   Header: "Baixar Anexo",
      //   accessor: "linkAnexo",
      //   Cell: ({ row }) => (
      //     <button className='flex text-white rounded bg-[#1346ED] font-normal text-sm py-2 px-6'>
      //       <FiDownload className='text-white text-xl ml-1' />
      //     </button>
      //   ),
      // },
    ],
    []);

  const [filtros, setFiltros] = //useState('aaaaaaaaaaa');
    useState({
      nPI: '',
      cliente: '',
      cnpj: '',
      data: ['', ''],
      status: '',
      valor: ['', '']
    });

  useEffect(() => {
    // console.log('COLUMNS');
    // console.log([typeof filtros]);
    // console.log(columns);
    // console.log(['useEffect', pageSize]);
    setCompras(() => {
      setLoading(true);
      setBeginSearch(false);
      const token = localStorage.getItem("token");
      // console.log(['GO FETCH!',filtros]);
      fetch(process.env.REACT_APP_API_BACK_URL + '/api/list/historico-de-compras/' + pageSize + '?page=1'
        + '&npi=' + (typeof filtros.nPI === 'undefined' || filtros.nPI === null ? '' : filtros.nPI)
        + '&company=' + (typeof filtros.cliente === 'undefined' || filtros.cliente === null ? '' : filtros.cliente)
        + '&cnpj=' + (typeof filtros.cnpj === 'undefined' || filtros.cnpj === null ? '' : filtros.cnpj)
        + '&status=' + (typeof filtros.status === 'undefined' || filtros.status === null ? '' : filtros.status)
        + '&date_from=' + (typeof filtros.data === 'undefined' || filtros.data[0] === null ? '' : filtros.data[0])
        + '&date_to=' + (typeof filtros.data === 'undefined' || filtros.data[1] === null ? '' : filtros.data[1])
        + '&val_min=' + (typeof filtros.valor === 'undefined' || filtros.valor[0] === null ? '' : filtros.valor[0])
        + '&val_max=' + (typeof filtros.valor === 'undefined' || filtros.valor[1] === null ? '' : filtros.valor[1])
        , {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': "Bearer " + token
          }
        })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          // console.log(['---------------------------',data.data.filtro.empresa])
          setTotalItens(data.data.total);
          setCompras(data.data.compras.data);
          setFiltroEmpresas(data.data.filtro.empresa);
          setfiltroMinMax(data.data.filtro.valor);
        })
        .catch((err) => {
          // Erro
        })
        .finally(() => {
          setLoading(false);
        });
    });
  },
    [pageSize, numPi, columns, filtros]);

  // useEffect(() => {
  //   console.log([
  //     'FILTROS 1',
  //     filtros,
  //   ]);
  // },
  //   [filtros]);

  return (
    <div className="min-h-screen bg-white text-gray-900">
      <main className="max-w-[90%] mx-auto px-4 sm:px-6 lg:px-8 pt-4">
        <h1 className='font-bold text-2xl text-[#1e1e1e] pt-8 pb-3'>Histórico de compra</h1>
        <div className="mt-4 w-auto">
          {
            (loading && (!compras || Object.keys(compras).length < 0)) ?
              <div class="max-w-sm w-full mx-auto flex items-center justify-center min-h-[300px]">
                <div class="grid gap-3">
                  <div class="flex items-center justify-center">
                    <svg class="animate-spin border-[#1346ed]" xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                      <g id="Group 1000003709">
                        <circle id="Ellipse 717" cx="21" cy="21" r="17.9883" stroke="#D1D5DB" stroke-width="5" stroke-dasharray="4 4" />
                        <path id="Ellipse 715" d="M26.2226 38.2135C30.4948 36.9173 34.1323 34.0787 36.428 30.2497C38.7237 26.4206 39.5134 21.8746 38.6437 17.4957C37.7739 13.1167 35.3069 9.21759 31.7221 6.55647C28.1374 3.89535 23.6911 2.66236 19.2479 3.09723" stroke="#1346ed" stroke-width="5" />
                      </g>
                    </svg>
                  </div>
                  <span class="text-black text-sm font-normal leading-snug">Carregando informações..</span>
                </div>
              </div>
              :
              (
                // compras && Object.keys(compras).length > 0 ? (
                <Tabela
                  columns={columns}
                  data={compras}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  setNumPi={setNumPi}
                  filtros={filtros}
                  setFiltros={setFiltros}
                  filtroEmpresas={filtroEmpresas}
                />
                // ) : ( <></> )
                // // <Tabela
                // //   columns={columns}
                // //   data={compras}
                // //   pageSize={pageSize}
                // //   setPageSize={setPageSize}
                // //   setNumPi={setNumPi}
                // //   filtros={filtros}
                // //   setFiltros={setFiltros}
                // // />
                // <div className='flex justify-center align-middle relative top-[20vh]'>
                //   <div className='grid text-center'>
                //     <div className='flex justify-center mb-3'>
                //       <img alt="" src='./img/icone-n-encontrado-portal-solucoes.png' width='113' height='97' />
                //     </div>
                //     <span className='text-[#112587] font-bold text-xl'>Nenhuma informação encontrada.</span>
                //   </div>
                // </div>
                // )
              )
          }


        </div>
      </main>
    </div>
  )
}