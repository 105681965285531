import React from "react"

export default function DashBannerBoasvindas(){
    return(
        <>
            <div className="grid grid-cols-1 lg:grid-cols-2 w-full h-full rounded-lg items-center bg-gradient-to-r from-[#1346ED] to-[#1ABDFF]">
                <div className="grid col-span-1 m-16">
                    <span className="text-3xl font-bold text-white">
                        Boas vindas, 
                        <br />{localStorage.getItem('user').split(' ')[0]}
                    </span>
                    <span className="mt-5 text-sm text-white font-light leading-[22px]">
                        Aqui você tem acesso aos seus boletos e notas fiscais, cadastra novas empresas para futuros negócios e fica por dentro de oportunidades no Grupo Massa.
                    </span>
                </div>
                <div className="col-span-1 m-auto bg-[url(https://solucoesmassa.com.br/wp-content/uploads/2024/11/810d5c112d6f6784d172337fac5a3944-flip-1.webp)] bg-contain bg-no-repeat">
                    <img src="https://solucoesmassa.com.br/wp-content/uploads/elementor/thumbs/Ratinho_anunciar-qnfva24r35qnfbkqq13ss438crkzlphi5t8y20ao00-qxa9voyj9j1iu5ck5i82e3s3vaalff3hv4tu400wio.webp" />
                </div>
            </div>
        </>
    )
}