import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function GoogleCallback() {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    // const [user, setUser] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {

        fetch(process.env.REACT_APP_API_BACK_URL + `/api/auth/google/callback${location.search}`,{
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            setLoading(false);
            setData(data);
        });

    }, []);

    if(loading){
        return <DisplayLoading/>
    } else{
        if(data.success && data.data.name != null){
            localStorage.setItem('token', data.data.token);
            localStorage.setItem('user', data.data.name);
            localStorage.setItem('avatar', data.data.avatar);
            localStorage.setItem('group', data.data.group);
            localStorage.setItem('empresas_vinculadas', data.data.empresas_vinculadas);
            navigate('/dash');
        } else{
            navigate('/', { state: {alert: true, alertMsg: 'Não foi possível se conectar.'}});
        }
    }

}

function DisplayLoading(){
    return <div class="max-w-sm w-full mx-auto flex items-center justify-center min-h-screen overflow-hidden">
                <div class="grid gap-3">
                    <div class="flex items-center justify-center">
                        <svg class="animate-spin border-[#1346ed]" xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                            <g id="Group 1000003709">
                                <circle id="Ellipse 717" cx="21" cy="21" r="17.9883" stroke="#D1D5DB" stroke-width="5" stroke-dasharray="4 4" />
                                <path id="Ellipse 715" d="M26.2226 38.2135C30.4948 36.9173 34.1323 34.0787 36.428 30.2497C38.7237 26.4206 39.5134 21.8746 38.6437 17.4957C37.7739 13.1167 35.3069 9.21759 31.7221 6.55647C28.1374 3.89535 23.6911 2.66236 19.2479 3.09723" stroke="#1346ed" stroke-width="5" />
                            </g>
                        </svg>
                    </div>
                <span class="text-black text-sm font-normal leading-snug">Entrando..</span>
                </div>
            </div>
}

export default GoogleCallback;