import React, { useState, useEffect } from 'react';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { RxDotFilled } from 'react-icons/rx';

function DashFullSlider() {
  const slides = [
    {
      url: 'https://grupomassa.com.br/wp-content/uploads/2024/12/BANNERS_PORTAL_MASSACOMBRDESKTOP_1536x440.webp',
      urlMobile: 'https://grupomassa.com.br/wp-content/uploads/2024/12/BANNERS_PORTAL_MASSACOMBRMOBILE_440x440.webp'
    },
    {
      url: 'https://solucoesmassa.com.br/wp-content/uploads/2023/11/27NOV-Banner-LiderancaMassaFM-1536x440-1.png',
      urlMobile: 'https://grupomassa.com.br/wp-content/uploads/2023/12/banner-mfm-mobile.jpg'
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const autoplay = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };
  
  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  useEffect(() => {
    const interval = setInterval(autoplay, 6000);
    return () => clearInterval(interval);
  }, [currentIndex]);

  return (
    <div className='max-h-[440px] h-[440px] w-full m-auto relative group'>
      <img src={slides[currentIndex].url} className="hidden lg:block w-full h-full transition-opacity duration-500 ease-in-out object-scale-down rounded-lg" alt='banner-desktop'/>
      <img src={slides[currentIndex].urlMobile} className="block lg:hidden w-full h-full transition-opacity duration-500 ease-in-out object-cover rounded-lg" alt='banner-mobile' />
      <AiOutlineLeft
            onClick={prevSlide}
            className="absolute left-0 text-2xl inset-y-1/2 text-white cursor-pointer"
          />
      <AiOutlineRight
            onClick={nextSlide}
            className="absolute right-0 text-2xl inset-y-1/2 text-white cursor-pointer"
          />
      <div className='flex top-4 justify-center -mt-10'>
        {slides.map((slide, slideIndex) => (
          <div
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
            className={`cursor-pointer ${
                slideIndex === currentIndex ? 'text-blue-500 text-4xl -mt-[6px]' : 'text-2xl text-white opacity-85 hover:opacity-100 hover:text-blue-400'
              }`}
          >
            <RxDotFilled />
          </div>
        ))}
      </div>
    </div>
  );
}

export default DashFullSlider;